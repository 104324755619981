<template>
  <div>
    <div v-if="dataSync.length > 0" class="py-3 px-3 table-order-fees">
      <v-row class="v-data-table v-card v-sheet v-sheet--outlined theme--light">
        <v-col cols="12" class="v-data-table__wrapper">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Fee name</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(orderFee, idx) in dataSync" :key="idx">
                <td>{{ idx + 1 }}</td>
                <td>
                  <v-text-field
                    v-model="orderFee.fee_name"
                    dense
                    label="Tên chi phí"
                    outlined
                    :disabled="!orderFee.not_save"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="orderFee.fee_amount"
                    dense
                    placeholder="đơn vị USD"
                    label="Giá"
                    outlined
                    type="number"
                    :disabled="!orderFee.not_save"
                  ></v-text-field>
                </td>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: "UpdateOrderFee",
  props: {
    order_fees: {
      type: Array,
    },
    orderId: {
      type: [Number, String],
    }
  },
  data: () => ({
    item: {},
  }),
  computed: {
    dataSync: {
      get: function () {
        return this.order_fees;
      },
      set: function (newValue) {
        this.$emit("order_fees", newValue);
      },
    },
  },
  methods: {
  },
  watch: {
    orderFee: {
      handler() {
        if (this.orderFee) this.item = this.orderFee;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.closed {
  /* position: absolute;
  right: -1rem; */
  background: var(--v-error-lighten1);
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
.check {
  background: #4caf4f;
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
</style>
<style lang="scss">
.table-order-fees {
  .v-text-field__details {
    display: none;
  }
}
</style>
