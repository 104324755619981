import FileSaver from 'file-saver';

export default {
  data: () => ({
    pickedItems: [],
    selectedItem: null,
    idsToDelete: [],

    idsToPrintLabel: [],
    tblLoading: false,
    dialog: {
      create: false,
      detail: false,
      update: false,
      delete: false,
      payOrders: false,
      import: false,
      printLabel: false,
      exportOrder: false,
      downloadLabel: false,
    },
    querySilent: true,
    tblConfig: {
      footerProps: {
        showFirstLastPage: true,
        'items-per-page-options': [15, 25, 50, 100, -1]
      },
      showSelect: true,
      text: {
        loading: 'Loading ...',
        noItem: 'No item found'
      },
    },
    tblOptions: {}
  }),
  computed: {
    listItems() {
      return this.items
    },
    getIndexOfItem() {
      return item => {
        let pageOffset = this.tblPagination ? (this.tblPagination.current_page - 1) * this.tblPagination.per_page : 0
        return pageOffset + this.items.indexOf(item) + 1
      }
    },
    queryConf() {
      let conf = {
        options: this.tblOptions,
        searchWith: this.searchWith,
        querySilent: this.querySilent
      }
      let sortBy = conf.options.sortBy;
      let sortDesc = conf.options.sortDesc;
      if (!sortBy) sortBy = [];
      if (!sortDesc) sortDesc = [];
      if (sortBy.indexOf('created_at') < 0 && sortBy.length === 0) {
        sortBy.unshift('created_at')
        sortDesc.unshift(true)
      } else if(sortBy.length > 1) {
        sortBy.splice(sortBy.indexOf('created_at'), 1);
        sortDesc.splice(0, 1);
      }
      conf.options.sortBy = sortBy;
      conf.options.sortDesc = sortDesc;
      return conf
    },
    needPayOrder() {
      return props => {
        let status = props.item.status;
        return status === this.CONSTANTS.ORDER_STATUS.NEW_WAIT_CONF || status === this.CONSTANTS.ORDER_STATUS.UPDATE_WAIT_CONF;
      }
    },
    canEditOrder() {
      return props => {
        if ([this.CONSTANTS.ROLES.SUPER_ADMINISTRATOR, this.CONSTANTS.ROLES.MANAGER, this.CONSTANTS.ROLES.SUPERVISOR, this.CONSTANTS.ROLES.SALE].indexOf(this.UserRole) > -1) return true;
        let status = props.item.status;
        return status === this.CONSTANTS.ORDER_STATUS.NEW_WAIT_CONF;
      }
    }
  },
  methods: {
    onClickRow($event, row) {
      console.log('$event', $event);
      this.selectedItem = row.item;
      this.dialog.detail = true;
    },

    onClickNew() {
      this.dialog.create = true;
    },

    onClickImport() {
      this.dialog.import = true;
    },

    onClickExport() {
      this.exportListItems({
        ...this.queryConf,
        searchFields: this.searchFields,
      }).then(res => {
        let fileName = res.headers && res.headers['content-disposition'] ? res.headers['content-disposition'].replace('attachment; filename=', '') : null;
        if (fileName) FileSaver.saveAs(res.data, fileName);
        else FileSaver.saveAs(res.data);
      }).catch(err => {
        console.log(err.message)
      })
    },
    /**
     * open confirm box for delete 1 item
     * @param props
     */
    onClickDelete(props) {
      this.dialog.delete = true;
      this.idsToDelete = [props.item.id];
    },

    onClickEdit(props) {
      this.selectedItem = props.item;
      this.dialog.update = true;
    },
    onClickDetail(props) {
      this.selectedItem = props.item;
      this.dialog.detail = true;
    },
    onClickPrintLabel() {
      this.dialog.printLabel = true;
      this.idsToPrintLabel = this.pickedItems.map(i => i.id);
    },
    async onClickBuyTracking() {
      let idsToBuyTracking = this.pickedItems.map(i => i.id);
      await this.requestBuyTrackingNumber({ids: idsToBuyTracking});
      this.doSearch();
    },
    /**
     * open confirm box for delete multi item
     */
    onClickDeleteAll() {
      this.dialog.delete = true;
      this.idsToDelete = this.pickedItems.map(i => i.id);
    },
    calculateTotalFee(order) {
      let totalFee = 0;
      if (!order.order_fees) return totalFee;
      order.order_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
      return parseInt(totalFee);
    },
    calculateRemainFee(order) {
      if (!order.payments) return 0;
      let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
      let payment_refunds = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.REFUND && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
      let paidFee = 0;
      let refund = 0;
      payments.forEach(p => paidFee += parseFloat(p.amount));
      payment_refunds.forEach(p => refund += parseFloat(p.amount));
      let totalFee = this.calculateTotalFee(order);
      return totalFee + refund - paidFee;
    },
    onClickSelectOrderUnpaid() {
      this.pickedItems = [];
      this.listItems.forEach(order => {
        const remainFee = this.calculateRemainFee(order);
        if (remainFee > 0) this.pickedItems.push(order);
      })
    },
    onClickPayMultiOrders() {
      this.dialog.payOrders = true;
      this.idsToPay = this.pickedItems.map(i => i.id);
    },
    /**
     * callback after created
     */
    onCreated() {
      this.dialog.create = false;
      this.doSearch();
    },
    /**
     * callback after updated
     */
    onUpdated() {
      this.dialog.update = false;
      this.doSearch();
    },
    /**
     * callback after delete items
     */
    onDeleted() {
      this.dialog.delete = false;
      this.idsToDelete = [];
      this.doSearch();
    },
    onPaid() {
      this.dialog.payOrders = false;
      this.idsToPay = [];
      this.doSearch();
    },
    /**
     * callback after import items
     */
    onImported() {
      this.dialog.import = false;
      this.doSearch();
    },

    doSearch() {
      this.pickedItems = [];
      this.selectedItem = null;
      this.tblLoading = true;
      if (this.list_code !== '')
        this.searchFields.arr_code.value = this.list_code.split('\n').filter(c => c.trim() !== '')
      else this.searchFields.arr_code.value = []
      if (this.searchFields.departed_date_from.value && this.searchFields.departed_date_to.value) {
        const fromData = this.searchFields.departed_date_from.value;
        const toData = this.searchFields.departed_date_to.value;
        if (new Date(this.searchFields.departed_date_from.value) > new Date(this.searchFields.departed_date_to.value)) {
          this.searchFields.departed_date_from.value = toData;
          this.searchFields.departed_date_to.value = fromData;
        }
      }
      if (this.searchFields.received_date_from.value && this.searchFields.received_date_to.value) {
        const fromData = this.searchFields.received_date_from.value;
        const toData = this.searchFields.received_date_to.value;
        if (new Date(this.searchFields.received_date_from.value) > new Date(this.searchFields.received_date_to.value)) {
          this.searchFields.received_date_from.value = toData;
          this.searchFields.received_date_to.value = fromData;
        }
      }
      if (this.searchFields.created_date_from.value && this.searchFields.created_date_to.value) {
        const fromData = this.searchFields.created_date_from.value;
        const toData = this.searchFields.created_date_to.value;
        if (new Date(this.searchFields.created_date_from.value) > new Date(this.searchFields.created_date_to.value)) {
          this.searchFields.created_date_from.value = toData;
          this.searchFields.created_date_to.value = fromData;
        }
      }
      if (this.searchFields.buy_tracking_date_from.value && this.searchFields.buy_tracking_date_to.value) {
        const fromData = this.searchFields.buy_tracking_date_from.value;
        const toData = this.searchFields.buy_tracking_date_to.value;
        if (new Date(this.searchFields.buy_tracking_date_from.value) > new Date(this.searchFields.buy_tracking_date_to.value)) {
          this.searchFields.buy_tracking_date_from.value = toData;
          this.searchFields.buy_tracking_date_to.value = fromData;
        }
      }
      if (this.searchFields.delivered_date_from.value && this.searchFields.delivered_date_to.value) {
        const fromData = this.searchFields.delivered_date_from.value;
        const toData = this.searchFields.delivered_date_to.value;
        if (new Date(this.searchFields.delivered_date_from.value) > new Date(this.searchFields.delivered_date_to.value)) {
          this.searchFields.delivered_date_from.value = toData;
          this.searchFields.delivered_date_to.value = fromData;
        }
      }
      this.getListItems({
        ...this.queryConf,
        searchFields: this.searchFields,
      }).then(res => {
        if (this.tblOptions.page > res.data.last_page) this.tblOptions.page = res.data.last_page;
      }).finally(() => {
        this.tblLoading = false
      });
    },
    async searchOrderHandleExport(searchData, options) {
      if (!options) options = {
        itemsPerPage: 1500,
      }
      if (searchData.departed_date_from.value && searchData.departed_date_to.value) {
        const fromData = searchData.departed_date_from.value;
        const toData = searchData.departed_date_to.value;
        if (new Date(searchData.departed_date_from.value) > new Date(searchData.departed_date_to.value)) {
          searchData.departed_date_from.value = toData;
          searchData.departed_date_to.value = fromData;
        }
      }
      if (searchData.received_date_from.value && searchData.received_date_to.value) {
        const fromData = searchData.received_date_from.value;
        const toData = searchData.received_date_to.value;
        if (new Date(searchData.received_date_from.value) > new Date(searchData.received_date_to.value)) {
          searchData.received_date_from.value = toData;
          searchData.received_date_to.value = fromData;
        }
      }
      if (searchData.created_date_from.value && searchData.created_date_to.value) {
        const fromData = searchData.created_date_from.value;
        const toData = searchData.created_date_to.value;
        if (new Date(searchData.created_date_from.value) > new Date(searchData.created_date_to.value)) {
          searchData.created_date_from.value = toData;
          searchData.created_date_to.value = fromData;
        }
      }
      if (searchData.buy_tracking_date_from.value && searchData.buy_tracking_date_to.value) {
        const fromData = searchData.buy_tracking_date_from.value;
        const toData = searchData.buy_tracking_date_to.value;
        if (new Date(searchData.buy_tracking_date_from.value) > new Date(searchData.buy_tracking_date_to.value)) {
          searchData.buy_tracking_date_from.value = toData;
          searchData.buy_tracking_date_to.value = fromData;
        }
      }
      if (searchData.delivered_date_from.value && searchData.delivered_date_to.value) {
        const fromData = searchData.delivered_date_from.value;
        const toData = searchData.delivered_date_to.value;
        if (new Date(searchData.delivered_date_from.value) > new Date(searchData.delivered_date_to.value)) {
          searchData.delivered_date_from.value = toData;
          searchData.delivered_date_to.value = fromData;
        }
      }
      let orders = [];
      try {
        await this.onSearchOrders({
          options: options,
          searchFields: searchData,
        }).then(res => {
          orders = res;
        })
      } catch (e) {
        console.log(e)
      }
      return orders;
    },
  },
  watch: {
    'queryConf': {
      handler() {
        this.doSearch();
      },
      deep: true
    },
  }
}
